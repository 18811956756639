<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <div class="block mb-1" v-if="returnText && returnToFunction">
          <a class="link_to is-inline-flex is-align-items-center" @click="returnToFunction">
            <i class="material-icons mr-2 is-flex">west</i>
            <span>{{ returnText }}</span>
          </a>
        </div>
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <template v-if="modeEdit">
                <input
                  class="input"
                  type="text"
                  name="titleEdit"
                  v-model="titleEdit"
                  v-validate="'required'"
                  data-vv-as="del título"
                  placeholder="Título"
                  @change="$emit('set-value', { name: 'name', value:  titleEdit })"
                >
              </template>
              <template v-else>
                <h1 class="title_level">
                  {{ title }}
                </h1>
              </template>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <slot></slot>
          </div>
        </nav>
        <div class="block" v-show="vverrors.has('titleEdit')">
          <span v-show="vverrors.has('titleEdit')" class="help is-danger is-block">{{ vverrors.first('titleEdit') }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleSection',
  inject: ['$validator'],
  data () {
    return {
      titleEdit: this.title
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    returnText: {
      type: String,
      default: null
    },
    returnToFunction: {
      type: Function,
      default: () => {}
    },
    modeEdit: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero {
    background-color: #e8e8e8;
  }
  .hero-body {
    padding: 1rem 1.5rem;
  }
  .level:not(:last-child) {
    margin-bottom: 0;
  }

  .level-item {
    justify-content: flex-start;
  }

  .level-left {
    width: 50%;
  }

  .level-left .level-item, .input {
    width: 100%;
  }

  .link_to {
    color: #458140;
    font-weight: bold;
  }
  .link_to i {
    font-size: 20px;
  }
  .link_to span {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    .level-left {
      width: 100%;
    }
  }
</style>
